.contactPageContainer {
    overflow-x: hidden;
    background-image: url('./../../../../images/index.png');
    background-repeat: no-repeat;
    background-position: center;
    // background-size: cover;

    @media screen and (max-width: 1000px) {
        background-image: none;
    }

    @media screen and (min-width: 1921px) {
        background-size: cover;
    }

    .ICContainer {
        margin-left: 140px;
        margin-right: 140px;
        padding-top: 25px;
        border-top: 2px rgb(231, 231, 231) solid;
    
        @media screen and (max-width: 1000px) {
            margin-left: 50px;
            margin-right: 50px;
            border-top: none;
        }
    }

    .contactMapContainer {
        width: 100vw;
        display: flex;
        flex-direction: row;
        padding-bottom: 50px;
        padding-top: 60px;
        
        @media screen and (max-width: 1000px) {
            flex-direction: column;
            padding-top: 20px;
            padding-bottom: 0;
            align-items: center;
        }

        .divider {
            display: none;
            visibility: hidden;

            @media screen and (max-width: 1000px) {
                display: block;
                visibility: visible;
                width: 90vw;
                height: 2px;
                background-color: rgb(231, 231, 231);
                margin-top: 25px;
                margin-bottom: 25px;
            }
        }

        .contactWrapper {
            width: 50vw;
            display: flex;
            flex-direction: column;
            padding-left: 125px;

            @media screen and (max-width: 1000px) {
                width: 100vw;
                padding-left: 0;
                padding-left: 25px;
            }

            .categoryWrapper {
                display: flex;
                flex-direction: column;
                margin-left: 25px;
                margin-top: 25px;

                @media screen and (max-width: 1000px) {
                    margin-top: 40px;
                }

                
                @media screen and (max-width: 700px) {
                    margin-left: 0;
                    margin-top: 25px;
                    max-width: 90vw;
                }

                h2 {
                    font-weight: 600;
                }

                .personListWrapper {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    
                    .personWrapper {
                        display: flex;
                        flex-direction: column;
                        width: 350px;
                        margin-top: 25px;

                        @media screen and (max-width: 700px) {
                            margin-top: 20px;
                        }

                        h3 {
                            font-size: 1.35em;
                            font-weight: 600;
                        }

                        .infoRow {
                            margin-top: 12px;
                            img {
                                width: 20px;
                                margin-right: 15px;
                            }
                        }

                        .mail {
                            img {
                                width: 25px;
                                margin-right: 10px;
                            }
                        }

                        .phone {
                            img {
                                width: 15px;
                                // margin-right: 20px;
                                margin-left: 5px;
                            }
                        }
                    }

                }

            }
        }

        .mapWrapper {
            width: 50vw;
            display: flex;
            flex-direction: column;
            margin-top: 25px;

            @media screen and (max-width: 1000px) {
                width: 100vw;
                padding-left: 0;
                padding-bottom: 0;
                align-items: center;
                margin-top: 0;

                iframe {
                    width: 100vw; 
                }
            }
            
            .textPart {
                display: flex;
                flex-direction: row;

                @media screen and (max-width: 1000px) {
                    padding-left: 00px;
                    margin-bottom: 20px;
                }

                .mapIconWrapper {
                    margin-right: 20px;
                }
            }

            h2 {
                font-weight: 600;
            }
        }
    }
}