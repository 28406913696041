.vehicleDetailContainer {
    .carDetailContainer {
        padding-top: 150px;

        h1 {
            font-weight: 600;
            font-size: 3em;
            text-align: center;
        }

        .goBackArrowText {
            display: flex;
            flex-direction: row;
            padding-left: 100px;
            align-items: center;
            
            @media screen and (max-width: 1000px) {
                padding-left: 50px;
            }

            img {
                margin-right: 15px;
            }
        }

        .contentContainer {
            margin-top: 50px;
            display: flex;
            flex-direction: row;

            @media screen and (max-width: 1000px) {
                flex-direction: column;
            }

            .tableContainer {
                width: 30vw;
                padding-left: 150px;

                @media screen and (max-width: 1000px) {
                    padding-left: 50px;
                    padding-right: 50px;
                    width: 100vw;
                }

                @media screen and (max-width: 500px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                h2 {
                    font-weight: 600;
                }

                Table {
                    margin-bottom: 50px;
                    
                    tr {
                        height: 35px;
                    }

                    tr:nth-child(even) {
                        background-color: rgb(244, 244, 244);
                    }
                }

                .priceContainer {
        
                    @media screen and (max-width: 1000px) {
                        text-align: center;
                        padding-left: 0;
                    }
        
                    .oldPrice, .newPrice {
                        font-size: 60px;
                        font-weight: 600;
        
                        @media screen and (max-width: 700px) {
                            font-size: 50px;
                        }
                    }
        
                    .newPrice {
                        color: #bfbfbf;
                    }
        
                    .priceLabel {
                        margin: 0;
                    }
                }
            }

            .imgContainer {
                width: 70vw;
                padding-left: 150px;
                padding-right: 150px;

                @media screen and (max-width: 1000px) {
                    width: 100vw;
                    padding-top: 50px;
                    padding-left: 50px;
                    padding-right: 50px;
                }

                @media screen and (max-width: 500px) {
                    padding-left: 15px;
                    padding-right: 15px;
                    padding-top: 20px;
                }

                .underContantText {
                    padding-top: 35px;
    
                    @media screen and (max-width: 1000px) {
                        margin-top: 15px;
                    }
    
                    @media screen and (max-width: 700px) {
                        margin-top: 20px;
                    }
        
                    h2 {
                        font-weight: 600;
                    }
                }

                .padding {
                    padding-top: 0;
                }
            }
        }
    }

    .contactFormWrapper {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 50px;
        background-position: center;
        background-size: cover;

        @media screen and (max-width: 1000px) {
            margin-top: 50px;
        }
        
        h2 {
            margin-bottom: 40px;
            font-weight: 600;
            font-size: 3em;
            
            @media screen and (max-width: 1000px) {
                margin-bottom: 0px;
            }
        }
    }

    .errorContainer {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
            font-size: 45px;
            font-weight: 600;
            color: rgb(62, 62, 62);
            text-align: center;

            @media screen and (min-width: 1000px) {
                max-width: 1000px;
            }

            @media screen and (max-width: 700px) {
                font-size: 30px;
                max-width: 90vw;
            }
        }

        a {
            margin-top: 50px;
            color: #2a60f5;
            text-decoration: none;
        }
    }
}
