.landingStripContainer {
    width: 100vw;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    background-repeat: no-repeat;
    background-position: center; 
    text-align: center;
    padding-top: 40px;
    background-size: cover;

    @media only screen and (max-width: 530px) {
        padding: 50px;
        padding-top: 100px;
    }

    h1 {
        font-size: 3em;
    }
}