.closeDiv {
    visibility: hidden;
    display: none;

    @media screen and (max-width: 1000px) {
        visibility: visible;
        display: block;
        width: 100vw;
        height: 100vh;
        position: fixed;
    }
}


.navbarContainer {
    width: 100vw;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(29,29,29,1) 0%, rgba(24,24,24,1) 100%);
    
    position: fixed;
    z-index: 10;

    @media screen and (max-width: 1000px) {
        background: black;
    }

    .links {

        span {
            margin-left: 15px;
            margin-right: 15px;
            text-transform: uppercase;
            font-family: Montserrat;
            font-weight: 500;
            letter-spacing: 2px;
            font-size: 15px;
            
            @media screen and (max-width: 1000px) {
                font-size: 16px;
            }
        }

        a {
            text-decoration: none;

            @media screen and (max-width: 1000px) {
                margin-top: 15px;
            }
        }

        @media screen and (max-width: 1000px) {
            visibility: hidden;
            display: none;
        }

        .homeLogo {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    .logo {
        position: fixed;
        top: 16px;
        left: 25px;

        img {
            width: 115px;
        }

        @media screen and (max-width: 1000px) {
            top: 19px
        }
        @media screen and (max-width: 700px) {
            top: 17px
        }
    }

    .socialMedia {
        position: fixed;
        top: 16px;
        right: 12px;
        width: 20px;


        @media screen and (max-width: 1000px) {
            visibility: hidden;
            display: none;
        }
    }
    
    .navbarToggle {
        width: 25px;
        height: 25px;
        position: fixed;
        top: 16px;
        right: 20px;
        @media screen and (min-width: 1001px) {
            visibility: hidden;
            display: none;
        }
    }

    
}

.active {
    @media screen and (max-width: 1001px) {
        flex-direction: column;
        height: 175px;
        
        .links {
            padding-top: 180px;
            padding-left: 15px;
            padding-bottom: 10px;
            background-color: inherit;
            width: 100vw;
            display: flex;
            flex-direction: column;
            justify-content: left;
            visibility: visible;

            span {
                margin-top: 5px;
            }
        }
    
    }
    
}