.footer_container {
  overflow-x: hidden;
  display: flex;
  padding-top: 25px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0;
  }

  img {
    width: 150px;
  }

  .categoriesWrapper {
    display: flex;

    @media only screen and (max-width: 1300px) {
      flex-direction: column;
    }

    .section {
      margin: 0 50px;
      justify-content: left;
      flex-direction: column;
      width: fit-content;
  
      @media only screen and (max-width: 1300px) {
        margin: 10px 20px
      }
  
      .heading-container {
        .section_title {
          margin: 0;
          margin-bottom: 15px;
          font-size: 1.3em;
          font-weight: 600;
        }
      }
  
      .list_of_subcategories {
        padding: 0;
        list-style-type: none;
  
        li {
          margin-bottom: 5px;
  
          .subcategory {
            text-decoration: none;
            font-size: 0.9em;
          }
        }
      }
    }
  
    .openHours {
      h3 {
        font-size: 1em;
        font-weight: 600;
      }

      Table {
        font-size: 0.9em;

        h3 {
          margin: 0;
        }

        td {
          padding-top: 10px;
        }
      }
    }
  }

  
}