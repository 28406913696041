.galleryContainer {
    padding: 10px 10px;

    @media screen and (max-width: 700px) {
        padding: 5px;
    }

    .moveContainer {
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    
    }
    
    .moveContainer:hover {
        background-color: rgba($color: #000000, $alpha: 0.15);
        transition: 0.3s;
    }
    
    .dialog {
        background-color: white;
        height: 800px;
        @media screen and (max-width: 700px) {
            height: 300px;
        }
    }
}