.loadingPageContainer {
    width: 99.1vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    position: fixed;
    background-color: white;
    pointer-events: none;
}