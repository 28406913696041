.tileContainer {
    margin: 15px;
    
    img {
        height: 250px;

        @media screen and (max-width: 1000px) {
            width: 450px;
            height: fit-content;
            
        }

        @media screen and (max-width: 700px) {
            max-width: 90vw;
            
        }
        
    }
}