
.text_section_container {
    position: relative;
    display: flex;
    max-width: 100vw;
  
    .text_half_container {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
  
      @media only screen and (max-width: 1300px) {
        width: 100%;
      }
  
      .text_half_wrapper {
        display: flex;
        align-items: center;
  
        @media only screen and (max-width: 1300px) {
          padding: 0 50px;
        }
  
        @media only screen and (max-width: 530px) {
          padding: 0 19px;
        }
  
        .rectangle {
          width: 15px;
          margin-right: 50px;
          flex-shrink: 0;
          height: 500px;
  
          @media only screen and (max-width: 1300px) {
            display: none;
          }
        }
        
        .text_half {
          .text_section_title {
            margin-bottom: 15px;
            font-weight: 700;
            font-size: 4em;
            
            @media only screen and (max-width: 530px) {
                font-size: 3em;
            }
          }
      
          .text_section_subtitle {
            font-weight: 400;
            font-size: 2em;
            
            @media only screen and (max-width: 530px) {
                font-size: 1.5em;
            }
          }
  
          .text {
            font-size: 1em;
          }
  
          @media only screen and (max-width: 530px) {
            min-width: 90vw;
          }
        }
      }
    }
  
    .img_half_container {
      display: flex;
      align-items: center;
      width: 50%;
  
      @media only screen and (max-width: 1300px) {
        width: 100vw;
      }
  
      .img_half {
        margin-top: 30px;
        width: 60%;
  
        @media only screen and (max-width: 1300px) {
          width: 100%;
        }
      }
    }
  }
  
  
  .left {
    flex-direction: row-reverse;
  }
  
  .right {
    flex-direction: row;
  }
  
  .top {
    @media only screen and (max-width: 1300px) {
      flex-direction: column-reverse;
    }
  }
  
  .bot {
    @media only screen and (max-width: 1300px) {
      flex-direction: column;
    }
  }
  
  @mixin justify_content_small_desktop {
    @media only screen and (max-width: 1300px) {
      justify-content: center;
    }
  }
  
  .to_edge_right_full_screen_mobile {
    justify-content: flex-end;
    @include justify_content_small_desktop()
  }
  
  .to_edge_left_full_screen_mobile {
    justify-content: flex-start;
    @include justify_content_small_desktop()
  }
  
  .img_center {
    justify-content: center;
  }
  
  .img_on_the_left_from_text {
    padding-left: 3vw;
    padding-right: 10vw;
  }
  
  .img_on_the_right_from_text {
    padding-left: 10vw;
    padding-right: 3vw;
  }
  
  .img_full_width_mobile {
    max-width: 90%;
    height: auto;
  
    @media only screen and (max-width: 1300px) {
      min-width: 100%;
    }
  }
  
  .img_regular_width_mobile {
    max-width: 90%;
    height: auto;
  
    @media only screen and (max-width: 1300px) {
      max-width: 70%;
      min-width: 65%;
    }
  }