.moveContainer {
    width: 40px;
    height: 100%;
    // background-color: rgba($color: #000000, $alpha: 0.2);
    display: flex;
    justify-content: center;
    align-items: center;

}

.moveContainer:hover {
    background-color: rgba($color: #000000, $alpha: 0.15);
    transition: 0.3s;
}

.dialog {
    height: 800px;
    @media screen and (max-width: 700px) {
        height: 300px;
    }
}