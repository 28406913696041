.homePageContainer {
    overflow-x: hidden;

    .imagesContainer {

        @media screen and (max-width: 1000px) {
                background-image: none;
        }
    }

    .fbContact {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background: rgb(1,96,255);
        background: linear-gradient(90deg, rgba(1,96,255,1) 0%, rgba(65,78,255,1) 100%);
        align-items: center;
        height: 70px;
        color: white;
        margin-bottom: 40px;
        margin-top: 32px;

        @media screen and (max-width: 1000px) {
            margin-bottom: 0;
        }

        p {
            margin: 0;
            font-size: 1.2em;
            text-transform: uppercase;
            letter-spacing: 5px;
            font-weight: 600;

            @media screen and (max-width: 700px) {
                letter-spacing: 2px;
                font-size: 0.92em;
            }
        }
        
        span {
            margin-left: 20px;
            width: 20px;
        }
    }


    .tilesContainer {
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .sliderContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        
        .slider {
            width: 800px;
            height: 500px;
            margin-top: 25px;
            margin-bottom: 50px;

            @media screen and (max-width: 1000px) {
                width: 100vw;
            }

            button {
                margin-top: 25px;
                height: 500px;
            }
        }

        @media screen and (max-width: 1000px) {
            padding-bottom: 0;
        }
    }

    .vehicleOffersContainer {
        padding-left: 120px;
        padding-right: 120px;
        display: flex;
        justify-content: left;
        flex-direction: column;

        @media screen and (max-width: 1000px) {
            padding: 50px;
        }

        @media screen and (max-width: 700px) {
            padding: 25px;
        }

        h2 {
            text-align: center;
            font-weight: 700;
            font-size: 3em;
        }

        .formGroup {
            display: flex;
            justify-content: center;
        }

        .vehiclesContainer {
            padding-top: 25px;
            padding-bottom: 25px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            transition: height 3s;
            
            @media screen and (max-width: 1000px) {
                justify-content: center;
                align-items: center;
            }
            
        }
    }
}