.servicesPageContainer {
    overflow-x: hidden;

    background-image: url('./../../../../images/index.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: 1000px) {
            background-image: none;
    }

    .servisContainer {
        margin-top: 70px;
        margin-bottom: 60px;

        @media only screen and (max-width: 1000px) {
            margin-bottom: 30px;
        }

        .tableContainer {
            min-width: fit-content;
            max-width: 80%;
            
            Table {
                
                tr:nth-child(even) {
                    background-color: rgb(236, 236, 236);
                }
            }

            @media only screen and (max-width: 700px) {
            max-width: 80%;
        }
        }
    }

    .secondContainer {
        @media only screen and (max-width: 1000px) {
            margin-bottom: 0;
        }
    }
}