.landingSiteContainer {
    // height: 100vh;
    
    width: 100vw;
    height: 100vh;
    background-color: black;
    background: url("./../../../../images/uvodni.png");
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: bottom; 
    background-size: cover;

    h1 {
        color: white;
        font-family: Montserrat;
        font-weight: 700;
        font-size: 80px;
        margin-left: 150px;

        
        @media screen and (max-width: 1000px) {
            font-size: 70px;
            margin-left: 40px;
        }

        @media screen and (max-width: 700px) {
            font-size: 50px;
            margin-left: 50px;
        }

        
        @media screen and (max-width: 500px) {
            font-size: 50px;
            margin-left: 25px;
        }
    }
}