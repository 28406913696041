.gdprPageContainer {
    .gdprContainer {
        margin: 50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
            width: 80vw;
        }

        .links {
            text-align: left;
            width: 80vw;
        }
    }
}