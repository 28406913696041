.form {
    width: fit-content;
    padding: 50px;
    display: flex;
    flex-direction: column;
    width: 600px;
    border-radius: 3px;
    background-color: rgba(255,255,255,0.8);

    @media only screen and (max-width: 1000px) {
        padding: 30px;
        width: 100%;
        border-radius: 0;
        margin-top: 50px;
    }

    .input {
        margin-bottom: 20px;
        color: white;
        border-color: white;
    }

    .form-button {
        margin-top: 20px;
        width: fit-content;
    }
}